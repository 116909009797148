import { Button, Result } from 'antd'
import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import { httpGet } from '../../services/http'
import './style.less'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

export default function Step2Page() {
    const data = useStaticQuery(graphql`
        query {
            s1: file(relativePath: { eq: "dk-days.png" }) {
                childImageSharp {
                    fluid(maxHeight: 500) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
            s2: file(relativePath: { eq: "ex-max-count.png" }) {
                childImageSharp {
                    fluid(maxHeight: 120) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
        }
    `)
    useEffect(() => {
        httpGet('/landing/article/guide-step-2/read')
    }, [])

    return (
        <>
            <div id="progressBarContainer">
                <div
                    id="progressBar"
                    style={{
                        transform: 'scale(0.33,1)',
                    }}
                />
            </div>
            <Result
                icon={
                    <Img
                        className="guide-image"
                        alt="calendar example"
                        imgStyle={{objectFit: 'contain'}}
                        fluid={data.s1.childImageSharp.fluid}
                    />
                }
                title="42-days-rule counter"
                subTitle={
                    <div style={{ maxWidth: 550, margin: 'auto', textAlign: 'left' }}>
                        <p>
                            Define your days in Denmark, by clicking on the date in the calendar,
                            when you have entered, or plan to enter the Danish area of sovereignty.
                        </p>
                        <p>
                            Press “Start dk-period” and then again on the date when you left, or
                            plan to leave the Danish area of sovereignty again.
                        </p>
                        <h3 style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
                            Maximum day count within an exemption period
                        </h3>
                        <p>
                            Your maximum day count for each exemption period, is shown for the
                            entire exemption period date range. (Including for your future planed
                            DK-days)
                            <br />
                            Giving you an instant counter to monitor your compliance, while planning
                            and editing your future DK-days.
                        </p>
                        <div
                            className="inline-img"
                            style={{
                                width: '100%',
                                display: 'grid',
                                gridTemplateColumns: '3fr 2fr',
                            }}
                        >
                            <Img
                                className="guide-image-med"
                                alt="calendar example"
                                imgStyle={{objectFit: 'contain'}}
                                fluid={data.s2.childImageSharp.fluid}
                            />
                            <p>
                                The maximum day count in any rolling six months window. (Shown in
                                red, if the 42-days-rule is exceeded)
                            </p>
                        </div>
                    </div>
                }
                extra={[
                    <Link to="/app/">
                        <Button>skip</Button>
                    </Link>,
                    <Link to="/onboarding/step1/">
                        <Button>back</Button>
                    </Link>,
                    <Link to="/onboarding/step3/">
                        <Button type="primary">Next</Button>
                    </Link>,
                ]}
            />
        </>
    )
}
